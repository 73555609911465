import {
  OfferFuelTypeEnum,
  OfferGearTypeEnum,
  OfferTypeEnum,
  PollutantClassEnum,
  VehicleBatteryParams,
  VehicleFuelTypeEnum,
} from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

import { fuelTypeMatcher } from "../matchers/fuelTypeMatcher";
import { newVehicleTypeMatcher } from "../matchers/newVehicleTypeMatcher";

const requiredField = i18n.t("yup.mixed_required_field");

const standardFuels = [
  VehicleFuelTypeEnum.Diesel,
  VehicleFuelTypeEnum.Ethanol,
  VehicleFuelTypeEnum.Hydrogen,
  VehicleFuelTypeEnum.NaturalGas,
  VehicleFuelTypeEnum.Lpg,
  VehicleFuelTypeEnum.Petrol,
];

const hybridFuels = [
  VehicleFuelTypeEnum.Hybrid,
  VehicleFuelTypeEnum.HybridDiesel,
];

export const standardDataSchema = yup.object().shape({
  vehicle: yup.object({
    id: yup.number().nullable(),
  }).nullable(),
  fuelType: yup
    .string()
    .nullable()
    .required(requiredField),
  numberOfDoors: yup
    .number()
    .typeError(requiredField)
    .required(requiredField),
  engineDisplacementCcm: yup
    .number()
    .typeError(requiredField),
  gearType: yup
    .string()
    .nullable()
    .required(requiredField),
  powerKw: yup
    .number()
    .typeError(requiredField)
    .required(requiredField),
  horsepower: yup
    .number()
    .typeError(requiredField)
    .required(requiredField),
  vin: yup
    .string()
    .nullable(),
  internalNumber: yup
    .string()
    .nullable(),
  title: yup
    .string()
    .nullable()
    .required(requiredField),
  vehicleType: yup
    .string()
    .nullable(),
  registeredAt: yup
    .date()
    .when("vehicleType", {
      is: (value?: OfferTypeEnum) => value === OfferTypeEnum.Used,
      then: yup
        .date()
        .typeError(requiredField)
        .required(requiredField),
      otherwise: yup
        .date()
        .notRequired()
        .nullable(),
    }),
  drivenKm: yup
    .number()
    .typeError(requiredField)
    .required(requiredField),
  availability: yup
    .number()
    .typeError(requiredField)
    .required(requiredField),
  wltpConsumptionCombinedWeighted: yup
    .string()
    .when(["fuelType", "isPluginHybrid"], {
      is: (
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: boolean,
      ) => (
        fuelTypeMatcher([...hybridFuels])(fuelType)
          ? isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpConsumptionCombinedWithDischargedBattery: yup
    .string()
    .when(["fuelType", "isPluginHybrid", "vehicleType"], {
      is: (
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: boolean,
        vehicleType: OfferTypeEnum,
      ) => newVehicleTypeMatcher(vehicleType)
      && (
        fuelTypeMatcher(hybridFuels)(fuelType)
          ? isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpConsumptionCityWithDischargedBattery: yup
    .string()
    .nullable(),
  wltpConsumptionSuburbanWithDischargedBattery: yup
    .string()
    .nullable(),
  wltpConsumptionRuralWithDischargedBattery: yup
    .string()
    .nullable(),
  wltpConsumptionHighwayWithDischargedBattery: yup
    .string()
    .nullable(),
  wltpPowerConsumptionCombinedWeighted: yup
    .string()
    .when(["vehicleType", "fuelType", "isPluginHybrid"], {
      is: (
        vehicleType: OfferTypeEnum,
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: boolean,
      ) => newVehicleTypeMatcher(vehicleType)
      && (
        fuelTypeMatcher([...hybridFuels, ...standardFuels])(fuelType)
          ? isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpPowerConsumptionCity: yup
    .string()
    .nullable(),
  wltpPowerConsumptionSuburban: yup
    .string()
    .nullable(),
  wltpPowerConsumptionRural: yup
    .string()
    .nullable(),
  wltpPowerConsumptionHighway: yup
    .string()
    .nullable(),
  wltpConsumptionCombined: yup
    .string()
    .when(["fuelType", "isPluginHybrid", "vehicleType"], {
      is: (
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: boolean,
        vehicleType: OfferTypeEnum,
      ) => newVehicleTypeMatcher(vehicleType)
      && (
        fuelTypeMatcher([...hybridFuels, ...standardFuels])(fuelType)
          ? !isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpPowerConsumptionCombined: yup
    .string()
    .when(["vehicleType", "fuelType", "isPluginHybrid"], {
      is: (
        vehicleType: OfferTypeEnum,
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: string,
      ) => newVehicleTypeMatcher(vehicleType)
        && (
          fuelTypeMatcher([VehicleFuelTypeEnum.Electric])(fuelType)
            ? !isPluginHybrid
            : false
        ),
      then: yup
        .string()
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpConsumptionCity: yup
    .string()
    .nullable(),
  wltpConsumptionSuburban: yup
    .string()
    .nullable(),
  wltpConsumptionRural: yup
    .string()
    .nullable(),
  wltpConsumptionHighway: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionCombinedWeighted: yup
    .string()
    .when(["vehicleType", "fuelType", "isPluginHybrid"], {
      is: (
        vehicleType: OfferTypeEnum,
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: boolean,
      ) => newVehicleTypeMatcher(vehicleType)
      && (
        fuelTypeMatcher(hybridFuels)(fuelType)
          ? isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpCarbonicEmissionCombined: yup
    .string()
    .when(["vehicleType", "fuelType", "isPluginHybrid"], {
      is: (
        vehicleType: OfferTypeEnum,
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: boolean,
      ) => newVehicleTypeMatcher(vehicleType)
        && fuelType !== VehicleFuelTypeEnum.Hydrogen
        && (
          fuelTypeMatcher([...hybridFuels, ...standardFuels])(fuelType)
            ? !isPluginHybrid
            : false
        ),
      then: yup
        .string()
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  fuelConsumptionCombined: yup
    .string()
    .nullable(),
  fuelConsumptionCity: yup
    .string()
    .nullable(),
  fuelConsumptionHighway: yup
    .string()
    .nullable(),
  carbonicEmission: yup
    .string()
    .nullable(),
  pollutantClass: yup
    .string()
    .nullable(),
  wltpPollutantClass: yup
    .string()
    .nullable(),
  pollutantClassDetailed: yup
    .string()
    .nullable(),
  wltpPollutantClassDetailed: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionCombinedClass: yup
    .string()
    .when(["fuelType", "isPluginHybrid", "vehicleType"], {
      is: (
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: string,
        vehicleType: OfferTypeEnum,
      ) => newVehicleTypeMatcher(vehicleType)
      && (
        fuelTypeMatcher([...standardFuels, ...hybridFuels])(fuelType)
          ? !isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .typeError(requiredField)
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpCarbonicEmissionWeightedCombinedClass: yup
    .string()
    .when(["fuelType", "isPluginHybrid", "vehicleType"], {
      is: (
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: string,
        vehicleType: OfferTypeEnum,
      ) => newVehicleTypeMatcher(vehicleType)
      && (
        fuelTypeMatcher(hybridFuels)(fuelType)
          ? isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .typeError(requiredField)
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  wltpCarbonicEmissionCombinedWithDischargedBatteryClass: yup
    .string()
    .when(["fuelType", "isPluginHybrid", "vehicleType"], {
      is: (
        fuelType: VehicleFuelTypeEnum,
        isPluginHybrid: string,
        vehicleType: OfferTypeEnum,
      ) => newVehicleTypeMatcher(vehicleType)
      && (
        fuelTypeMatcher(hybridFuels)(fuelType)
          ? isPluginHybrid
          : false
      ),
      then: yup
        .string()
        .typeError(requiredField)
        .required(requiredField),
      otherwise: yup
        .string()
        .nullable(),
    }),
  energyEfficiencyClass: yup
    .string()
    .nullable(),
  powerConsumptionCombined: yup
    .string()
    .nullable(),
  rangeKm: yup
    .string()
    .nullable(),
  battery: yup.object().shape({
    type: yup
      .string()
      .nullable(),
    capacityKwh: yup
      .string(),
    chargeTimeHour: yup
      .string(),
    chargeTimeFastHour: yup
      .string(),
    chargeTimeFastAcHour: yup
      .string(),
  }),
  wltpCarbonicEmissionPriceAverage: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionPriceLowAverage: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionPriceHighAverage: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionPriceHighAverageAccumulated: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionPriceAverageAccumulated: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionPriceLowAverageAccumulated: yup
    .string()
    .nullable(),
  vehicleTax: yup
    .string()
    .nullable(),
  vehicleTaxPerYear: yup
    .string()
    .nullable(),
  powerPrice: yup
    .string()
    .nullable(),
  energyCostsPerAnnualMileage: yup
    .string()
    .nullable(),
  wltpCarbonicEmissionPricePeriodFromYear: yup
    .date()
    .when("wltpCarbonicEmissionPricePeriodToYear", {
      is: (
        wltpCarbonicEmissionPricePeriodToYear: Date,
      ) => !!wltpCarbonicEmissionPricePeriodToYear,
      then: yup
        .date()
        .typeError(requiredField)
        .required(requiredField),
      otherwise: yup
        .date()
        .nullable(),
    }),
  wltpCarbonicEmissionPricePeriodToYear: yup
    .date()
    .typeError(requiredField)
    .when("wltpCarbonicEmissionPricePeriodFromYear", {
      is: (
        wltpCarbonicEmissionPricePeriodFromYear: Date,
      ) => !!wltpCarbonicEmissionPricePeriodFromYear,
      then: yup
        .date()
        .typeError(requiredField)
        .required(requiredField),
      otherwise: yup
        .date()
        .nullable(),
    })
    .nullable(),
}, [
  ["wltpCarbonicEmissionPricePeriodFromYear", "wltpCarbonicEmissionPricePeriodToYear"],
  ["wltpConsumptionCombined", "fuelConsumptionCombined"],
  ["wltpPowerConsumptionCombined", "powerConsumptionCombined"],
]);

export interface FuelConsumptionSchema {
  wltpConsumptionCombined?: string;
  wltpConsumptionCombinedWeighted?: string;
  wltpPowerConsumptionCombined?: string;
  wltpConsumptionCity?: string;
  wltpConsumptionSuburban?: string;
  wltpConsumptionRural?: string;
  wltpConsumptionHighway?: string;
  fuelConsumptionCombined?: string;
  fuelConsumptionCity?: string;
  fuelConsumptionHighway?: string;
  energyEfficiencyClass?: string;
  powerConsumptionCombined?: string;
  rangeKm?: VehicleBatteryParams["rangeKm"];
  battery?: VehicleBatteryParams;
}

export interface StandardDataSchema extends FuelConsumptionSchema {
  vehicle?: { id: number };
  wltpCarbonicEmissionCombinedClass?: string;
  wltpCarbonicEmissionCombinedWithDischargedBatteryClass?: string;
  wltpCarbonicEmissionWeightedCombinedClass: string;
  wltpCarbonicEmissionCombined?: string;
  wltpCarbonicEmissionCombinedWeighted?: string;
  wltpConsumptionCombinedWithDischargedBattery?: string;
  wltpConsumptionCityWithDischargedBattery?: string;
  wltpConsumptionSuburbanWithDischargedBattery?: string;
  wltpConsumptionRuralWithDischargedBattery?: string;
  wltpConsumptionHighwayWithDischargedBattery?: string;
  wltpPowerConsumptionCombinedWeighted?: string;
  wltpPowerConsumptionCity?: string;
  wltpPowerConsumptionSuburban?: string;
  wltpPowerConsumptionRural?: string;
  wltpPowerConsumptionHighway?: string;
  wltpPollutantClassDetailed?: string;
  carbonicEmission?: string;
  pollutantClass?: PollutantClassEnum;
  wltpPollutantClass?: PollutantClassEnum;
  fuelType: OfferFuelTypeEnum;
  numberOfDoors: string;
  isPluginHybrid: boolean;
  engineDisplacementCcm: number;
  gearType: OfferGearTypeEnum;
  powerKw?: number;
  horsepower: number;
  vin: string;
  internalNumber: string;
  title: string;
  vehicleType: OfferTypeEnum;
  registeredAt: Date | null;
  drivenKm: number;
  availability: number;
  oneDayRegistration: boolean;
  equipmentConfigurable: boolean;
  pollutantClassDetailed?: string;
  wltpCarbonicEmissionPriceAverage?: string;
  wltpCarbonicEmissionPriceAverageAccumulated?: string;
  wltpCarbonicEmissionPriceLowAverage?: string;
  wltpCarbonicEmissionPriceLowAverageAccumulated?: string;
  wltpCarbonicEmissionPriceHighAverage?: string;
  wltpCarbonicEmissionPriceHighAverageAccumulated?: string;
  vehicleTax?: string;
  vehicleTaxPerYear?: string;
  powerPrice?: string;
  energyCostsPerAnnualMileage?: string;
  fuelPrice?: string;
  wltpCarbonicEmissionPricePeriodToYear: Date | null;
  wltpCarbonicEmissionPricePeriodFromYear: Date | null;
}
