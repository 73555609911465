import { Flex } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import ReactSelect, { OptionsType, OptionTypeBase } from "react-select";

import Select from "components/FormComponents/Inputs/Selects/Select";
import {
  GetVehiclesQuery, SortingOrderEnum, useGetVehiclesLazyQuery, VehicleSortFieldsEnum,
} from "generated/graphql";
import i18n from "translations/i18n";

interface VehicleSelectProps {
  placeholder?: string;
  selectedBrand: number;
  defaultValue?: number;
  onChange: (vehicleId: number) => void;
}

function getOptions(data: GetVehiclesQuery | undefined): OptionsType<OptionTypeBase> {
  if (!data) {
    return [];
  }

  return data.vehicles?.map(vehicle => ({
    value: vehicle.id,
    label: `${vehicle.modelGroup} ${vehicle.model}`,
  }));
}

const VehicleSelect = React.forwardRef<ReactSelect, VehicleSelectProps>((
  {
    placeholder = i18n.t("components.selects.vehicle"),
    selectedBrand,
    defaultValue,
    onChange,
    ...props
  },
  ref,
) => {
  const [selectedVehicleId, setSelectedVehicleId] = React.useState<
  number | undefined>(defaultValue);

  const [
    getVehicles,
    {
      data,
      error,
      loading,
    },
  ] = useGetVehiclesLazyQuery({
    variables: {
      filters: {
        brandId: selectedBrand,
      },
      pagination: {
        orderBy: [{ field: VehicleSortFieldsEnum.Model, order: SortingOrderEnum.Asc }],
      },
    },
  });

  useEffect(() => {
    if (!selectedBrand) {
      return;
    }

    getVehicles({
      variables: {
        filters: {
          brandId: selectedBrand,
        },
      },
    });
  }, [
    selectedBrand,
    getVehicles,
  ]);

  useEffect(() => {
    if (data && defaultValue !== undefined) {
      const exists = data.vehicles?.some(vehicle => vehicle.id === defaultValue);
      if (exists) {
        setSelectedVehicleId(defaultValue);
      } else {
        setSelectedVehicleId(undefined);
      }
    }
  }, [data, defaultValue]);

  const handleChange = useCallback((value) => {
    if (!selectedBrand) {
      return;
    }
    setSelectedVehicleId(value);
    onChange?.(value);
  }, [
    selectedBrand,
    onChange,
  ]);

  const options = selectedBrand ? getOptions(data) : [];

  const selectedOption = options.find(option => option.value === selectedVehicleId);

  const isDisabled = !!error || !selectedBrand;

  return (
    <Flex w="full">
      <Select
        value={selectedOption}
        isDisabled={isDisabled}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        isSearchable
        ref={ref}
        onChange={handleChange}
        {...props}
      />
    </Flex>
  );
});

export default VehicleSelect;
